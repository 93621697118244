
<template>
  <div>
    <!-- <div style="float:right;margin-bottom:10px">
      <qzf-video vid="4467be5374040c63897225ca85ad8ce8_4"></qzf-video>
    </div> -->
    
    <el-table :data="list" style="width: 100%" border :height="contentStyleObj">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column prop="name" label="项目名称" min-width="180" align="center">
      </el-table-column>
      <el-table-column prop="remark" label="设置" min-width="220" align="center">
        <template #default="scope">
          <subject-list v-if="scope.row.valueType == 'select'" :codes="$findCode(scope.row.option)" v-model:subjectId="scope.row.subjectId" v-model:fzhsItemId="scope.row.fzhsId"></subject-list>
          <el-radio-group v-if="scope.row.valueType == 'radio'" v-model="scope.row.value" size="small">
            <!-- <el-radio v-for="item in scope.row.option" :label="item.name" :key="item.value"></el-radio> -->
            <el-radio v-for="item in scope.row.option" :label="item.value" :key="item.index">{{item.name}}</el-radio>
          </el-radio-group>
          <el-input v-if="scope.row.valueType == 'input'" size="small" v-model="scope.row.value"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" min-width="180" align="center">
        <template #default="scope">
          <div v-if="scope.row.name == '减免土地税'" style="color:red">{{ scope.row.remark }}</div>
          <div v-else> {{ scope.row.remark }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="120" align="center">
        <template #default="scope">
          <el-button @click="saveSetting(scope.row)" type="text" size="small" v-if="$buttonStatus('qtsz_bc')">保存</el-button>
        </template>
      </el-table-column>
    </el-table>


    <div style="margin-top:10px;text-align: right;" v-if="$buttonStatus('qtsz_bc')">
      <el-button type="primary" @click="batchSave" size="small">批量保存</el-button>
    </div>
  </div>
</template>

<script>
import { getComSetList , setComSet, setComSets } from "@/api/company";
export default {
  name:'otherSetting',
  data() {
    return {
      list:[],
    }
  },
  created() {
    this.contentStyleObj= this.$getHeight(241)
    this.init()
  },
  methods: {
    init(){
      this.getList()
    },
    getList(){
      let comId = this.$store.getters["commons/params"].editCustomerComId * 1
      if(this.$store.getters["user/comInfo"].comId * 1){
        comId = this.$store.getters["user/comInfo"].comId * 1
      }else if(this.$store.getters["commons/params"].editCustomerComId.id *1){
        comId = this.$store.getters["commons/params"].editCustomerComId.id
      }
      getComSetList({comId}).then(res=>{
        this.list = res.data.data.list
      })
    },
    batchSave(){
      if(this.$store.getters["user/comInfo"].comId){
        if(this.$checkSettleStatus()) return
        setComSets(this.list).then(res =>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.dialogTableVisible = false
          }else{
            this.$qzfMessage("请查看设置项目",1)
          }
        })
      }else{
        setComSets(this.list).then(res =>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.dialogTableVisible = false
          }else{
            this.$qzfMessage("请查看设置项目",1)
          }
        })
      }
    },
    saveSetting(row){
      if(this.$store.getters["user/comInfo"].comId){
        if(this.$checkSettleStatus()) return
        setComSet(row).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
          }
        })
      }else{
        setComSet(row).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
          }
        })
      }
    },
  }
}
</script>

<style>

</style>