<template>
  <div class="othercss clearfix">
    <p>
      企业所得税以前年度亏损：
    </p>
    <div class="right_select">
      <i @click="handleAddItem(other)" class="iconfont icon-jiahao addi"></i>
      <div class="item_box" v-for="(row,index) in other" :key="index" >
        <el-select v-model="row.year" placeholder="请选择年份" size="small" style="width:150px" clearable>
          <el-option v-for="item in year" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-input v-model="row.quarter" placeholder="请输入金额" size="small" style="width:150px;margin-left:10px"></el-input>（元）
        
        <div class="close" @click="handleDelItem(other,index)">
          <i class="iconfont icon-shanchu"></i> 
        </div>
      </div>
    </div>
  </div>
  <el-button @click="kuisunSave" type="primary" size="small">
    <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 14px;"></i>
    <span  > 保存</span>
  </el-button>
</template>

<script>
import { gsOtherInfo,gsOtherSave } from '@/api/taxCalculation.js'
export default {
  name:'annualLoss',
  data() {
    return {
      //企业所得税以前年度亏损
      other:[
        {
          year: "", // 年份
          quarter: "", // 金额
        },
      ],
      year:[
        {
          value:'2010',
          label:'2010'
        },
        {
          value:'2011',
          label:'2011'
        },
        {
          value:'2012',
          label:'2012'
        },
        {
          value:'2013',
          label:'2013'
        },
        {
          value:'2014',
          label:'2014'
        },
        {
          value:'2015',
          label:'2015'
        },
        {
          value:'2016',
          label:'2016'
        },
        {
          value:'2017',
          label:'2017'
        },
        {
          value:'2018',
          label:'2018'
        },
        {
          value:'2019',
          label:'2019'
        },
        {
          value:'2020',
          label:'2020'
        },
        {
          value:'2021',
          label:'2021'
        },
        {
          value:'2022',
          label:'2022'
        },
        {
          value:'2023',
          label:'2023'
        },
        {
          value:'2024',
          label:'2024'
        },
        {
          value:'2025',
          label:'2025'
        },
        {
          value:'2026',
          label:'2026'
        },
        {
          value:'2027',
          label:'2027'
        },
        {
          value:'2028',
          label:'2028'
        },
        {
          value:'2029',
          label:'2029'
        },
        {
          value:'2030',
          label:'2030'
        }

      ],
      listQuery: {
        comId: this.$store.getters['user/comInfo'].comId*1,
      },
    }
  },
  created(){
    this.contentStyleObj= this.$getHeight(280)
    this.getListKS()
  },
  methods: {
    //企业所得税以前年度亏损
    getListKS() {
      this.listQuery.comId = this.$store.getters["commons/params"].editCustomerComId * 1
      if(this.$store.getters["user/comInfo"].comId * 1){
        this.listQuery.comId = this.$store.getters["user/comInfo"].comId * 1
      }else if(this.$store.getters["commons/params"].editCustomerComId.id *1){
        this.listQuery.comId = this.$store.getters["commons/params"].editCustomerComId.id
      }
      gsOtherInfo(this.listQuery).then(res=>{
        if(res.data.data.list.length != 0){
          this.other = res.data.data.list
        }else{
          this.other = [{
            year:"",
            quarter:""
          }]
        }
      })
    },
    // 添加一行
    handleAddItem(item) {
      // item.splice(1, 0, {
      //   year: "", // 年份
      //   quarter: "", // 金额
      // });
      item.unshift({
        year: "", // 年份
        quarter: "", // 金额
      })
    },
    // 删除一行
    handleDelItem(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
    },
    //保存
    kuisunSave(){
      this.other.map(v=>{
        v.quarter = Number(v.quarter);
      })
      gsOtherSave({comId:this.listQuery.comId,items:this.other}).then(res=>{
        if(res.data.msg == 'success'){
          this.$message({
            type: 'success',
            message: '保存成功'
          });
        }
        
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.othercss{
  p{
    float: left;
    width: 200px;
    font-size: 15px;
    line-height: 24px;
  }
  .right_select{
    float: left;
    position: relative;
    .add {
      width: 1.4em;
      height: 1.4em;
      vertical-align: -0.15em;
      fill: currentColor;
      position: absolute;
      left: -26px;
      top: 0;
      font-size: 16px;
      color: var(--themeColor,#17a2b8);
      cursor: pointer;
      padding-top: 4px;
      .svg-icon {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
  }
  .item_box {
    position: relative;
    margin-top: 0 !important;
    border-top: 0 !important;
    margin-bottom: 10px;
    .close {
      width: 1.4em;
      height: 1.4em;
      vertical-align: -0.15em;
      fill: currentColor;
      position: absolute;
      right: -32px;
      top: 3px;
      font-size: 20px;
      color: var(--themeColor,#17a2b8);
      cursor: pointer;
      .svg-icon {
        width: 100%;
        height: 100%;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
      }
    }
  }
  
}
.addi{
  cursor: pointer;
  position: absolute;
  top: 2px;
  left: -26px;
  font-size: 22px;
  color: var(--themeColor,#17a2b8);
}
</style>