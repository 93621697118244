<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="审核人指定"
    width="400px"
    destroy-on-close
  >
    <el-form :model="form">
      <el-form-item label="请指定审核人">
        <select-common
          placeholder="请指定审核人"
          v-model:modelValue="form.userId"
          :options="options"
          valueKey="userId"
          labelKey="userName"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="submitSure"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref } from "vue";
import { ElMessage } from "element-plus";
import { submitApproval, contractApprove, paymentApprove, refundApprove } from "@/api/newCrm";

const props = defineProps({
  type: {
    type: Number,
    default: 2,
  }
})
const emit = defineEmits();
const dialogFormVisible = ref(false);
const form = ref({
  id: null,
  userId: null,
});
const options = ref([]);
const apiObj = {
  2: contractApprove,
  3: paymentApprove,
  4: refundApprove,
}
const init = async (item) => {
  form.value.id = item.id;
  form.value.userId = null;
  await submitApproval({ id: item.id, type: props.type }).then((res) => {
    if (res.data.msg == "success") {
      options.value = res.data.data.list;
    }
  });
  dialogFormVisible.value = true;
};
const submitSure = () => {
  if (!form.value.userId) {
    ElMessage.warning("请指定审批人");
  } else {
    let info = options.value.find((item) => item.userId == form.value.userId);
    let param = {
      bingingId: [form.value.id],
      type: props.type,
      req: info.req,
      status: 5,
      userId: form.value.userId,
      userName: info.userName,
      remark: "",
    };
    const api = apiObj[props.type]
    api(param).then((res) => {
      if (res.data.msg == "success") {
        ElMessage.success("提交成功");
        dialogFormVisible.value = false;
        emit("success");
      }
    });
  }
};
defineExpose({
  init,
});
</script>
