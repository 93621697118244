<template>
  <div class="main">
    <div class="top_select">
      <div>
        <el-input
          size="small"
          v-model="fuzzyName"
          :style="{ width: '150px' }"
          @keyup.enter="getList()"
        ></el-input>
        <el-button type="primary" size="small" @click="getList" icon="Search"
          >搜索</el-button
        >
        <el-button type="text" size="small" @click="getList" icon="Refresh"
          >刷新</el-button
        >
      </div>
      <div>
        <!--  <span>文件夹数量：{{ info.folderSum }} &nbsp;&nbsp; 文件数量：{{ info.fileSum }}</span> -->
        <span class="hint"
          >使用空间 / 总空间：<span style="color:var(--themeColor)"
            >{{ info.memoryOccupied }}MB</span
          >
          / {{ info.totalMemory }}</span
        >
        <el-button size="small" type="primary" @click="addFile"
          >新建文件夹</el-button
        >
      </div>
    </div>
    <div>
      <el-table
        :data="fileList"
        style="width: 100%;margin-top: 10px;"
        border
        :height="contentStyleObj"
        v-loading="loading"
        :tree-props="{ children: 'item', hasChildren: 'hasChildren' }"
        row-key="id"
        default-expand-all
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column prop="name" label="文件名称" min-width="180">
          <template #default="scope">
            <span v-if="scope.row.fileType">
              <i
                class="iconfont icon-wenjianleixing-biaozhuntu-gongzuobiao"
                style="color:#4cb050"
                v-if="
                  scope.row.fileType == 'xlsx' || scope.row.fileType == 'xls'
                "
              ></i>
              <i
                class="iconfont icon-wenjianleixing-biaozhuntu-jishiben"
                style="color:#8289ad"
                v-else-if="scope.row.fileType == 'txt'"
              ></i>
              <i
                class="iconfont icon-wenjianleixing-biaozhuntu-huandengpian"
                style="color:#ff9333"
                v-else-if="scope.row.fileType == 'ppt'"
              ></i>
              <i
                class="iconfont icon-zip"
                style="color:#576a95"
                v-else-if="scope.row.fileType == 'zip'"
              ></i>
              <i
                class="iconfont icon-pdf"
                style="color:#fa4e4e"
                v-else-if="scope.row.fileType == 'pdf'"
              ></i>
              <i
                class="iconfont icon-wenjianleixing-biaozhuntu-Wordwendang"
                v-else-if="
                  scope.row.fileType == 'docx' || scope.row.fileType == 'doc'
                "
                style="color:#4876f9"
              ></i>
              <i
                class="iconfont icon-wenjianleixing-biaozhuntu-weizhiwenjian"
                style="color:#cccccc"
                v-else
              ></i> </span
            ><span v-else>
              <i
                class="iconfont icon-wenjianleixing-biaozhuntu-wenjianjia"
                style="color:#ffca28"
              ></i>
            </span>
            {{ scope.row.name }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="文件大小" min-width="180">
          <template #default="scope">
            <span>{{ scope.row.size }}MB</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="180" />
        <el-table-column align="center" label="操作" width="250">
          <template #default="scope">
            <el-button
              size="small"
              plain
              type="primary"
              @click="upload(scope.row)"
              v-if="!scope.row.filePath"
            >
              <el-icon><Upload /></el-icon><span> 上传文件</span>
            </el-button>
            <el-button
              size="small"
              plain
              type="success"
              @click="check(scope.row)"
              v-if="scope.row.filePath"
            >
              <el-icon><View /></el-icon><span> 查看文件</span>
            </el-button>
            <el-button
              size="small"
              plain
              type="danger"
              @click="del(scope.row, 2)"
              v-if="!scope.row.filePath"
            >
              <el-icon><Delete /></el-icon><span> 删除</span>
            </el-button>
            <el-button
              size="small"
              plain
              type="danger"
              @click="del(scope.row, 1)"
              v-if="scope.row.filePath"
            >
              <el-icon><Delete /></el-icon><span> 删除</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    v-model="dialogFileVisible"
    title="新建文件夹"
    width="30%"
  >
    <el-form>
      <el-form-item label="文件夹名称：" label-width="120px">
        <el-input
          v-model="fileName"
          style="width: 200px;"
          size="small"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFileVisible = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="addFileSure" size="small">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 上传文件 -->
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    v-model="dialogUploadVisible"
    title="上传文件"
    width="30%"
  >
    <el-input
      style="width: 60%; margin-right: 10px"
      v-model="path"
      size="small"
    ></el-input>
    <input
      size="small"
      ref="excel-upload-input"
      class="excel-upload-input"
      style="display: none"
      type="file"
      @change="handleClick"
    />
    <el-button type="primary" @click="handleUpload()" size="small"
      >请选择文件</el-button
    >
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogUploadVisible = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="uploadExcel" size="small">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {
  getFFList,
  createFolder,
  upload,
  delFF,
  storeStatistics,
} from "@/api/cloudDisk.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogFileVisible: false,
      fileName: "",
      taxNo: "",
      fileList: [],
      folderId: null,
      dialogUploadVisible: false,
      path: "",
      rawFile: "",
      fuzzyName: "",
      info: {},
      loading: false,
    };
  },
  mounted() {
    this.taxNo = this.$store.getters["user/comInfo"].taxNo;
    this.contentStyleObj = this.$getHeight(350);
    this.getList();
    this.getStore();
  },
  methods: {
    getList() {
      this.loading = true;
      getFFList({
        taxNo: this.taxNo,
        fuzzyName: this.fuzzyName,
      }).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.fileList = res.data.data;
        }
      });
    },
    getStore() {
      storeStatistics({
        taxNo: this.taxNo,
      }).then((res) => {
        if (res.data.msg == "success") {
          this.info = res.data.data;
        }
      });
    },
    addFile() {
      this.fileName = "";
      this.dialogFileVisible = true;
    },
    addFileSure() {
      createFolder({
        name: this.fileName,
        taxNo: this.taxNo,
      }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("新增成功");
          this.getList();
          this.dialogFileVisible = false;
        }
      });
    },
    check(row) {
      window.open(row.filePath + "?" + new Date().getTime());
    },
    del(row, type) {
      this.$confirm("确认删除此文件吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delFF({ id: row.id, fileType: type }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
            }
          });
        })

        .catch(() => {});
    },
    //上传文件
    upload(row) {
      this.folderId = row.id;
      this.dialogUploadVisible = true;
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile;
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    uploadExcel() {
      const param = new FormData(); // 创建form对象
      if (this.rawFile != "") {
        param.append("file", this.rawFile, this.rawFile.name);
        param.append("taxNo", this.taxNo);
        param.append("folderId", this.folderId);
      } else {
        this.$message.error("请选择文件");
      }
      upload(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("上传成功");
          this.dialogUploadVisible = false;
          this.getList();
        }
      });
    },
    beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (isLt5M) {
        return true;
      }
      this.$message({
        message: "请不要上传大于5M的文件。",
        type: "warning",
      });
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hint {
  padding-right: 10px;
  font-size: 14px;
}
</style>
