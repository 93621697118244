<template>
  <div style="position: relative;">
    <div style="position: relative;">
      <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
        <el-tab-pane label="企业基本信息" name="first">
          <basicInform></basicInform>
        </el-tab-pane>
        <el-tab-pane label="税种信息" name="information">
          <information></information>
        </el-tab-pane>
        <!-- <el-tab-pane label="其他设置" name="fourth">
          <otherSetting></otherSetting>
        </el-tab-pane> -->
        <!-- <el-tab-pane label="其他功能" name="fifth">
          <bindApplet></bindApplet>
        </el-tab-pane> -->
       <!--  <el-tab-pane label="我的云盘" name="sixth">
          <cloudDisk></cloudDisk>
        </el-tab-pane> -->
        <!-- <el-tab-pane label="企业所得税以前年度亏损" name="annualLoss">
          <annualLoss></annualLoss>
        </el-tab-pane> -->
        <!-- <el-tab-pane label="客户端账号" name="users"></el-tab-pane>
        <el-tab-pane label="B类企业" name="b"></el-tab-pane>
        <el-tab-pane label="发票采集账号" name="invoice"></el-tab-pane>
        <el-tab-pane label="基础科目设置" name="subject"></el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import annualLoss from "./components/annualLoss.vue"
import information from "./components/information.vue"
import otherSetting from "./components/otherSetting.vue"
import basicInform from "./components/basicInform.vue"
import bindApplet from "./components/bindApplet.vue"
import cloudDisk from "./components/cloudDisk.vue"
export default {
  name: 'comInfo',
  components: { annualLoss,information,otherSetting,basicInform,bindApplet,
    cloudDisk 
  },
  data() {
    return {
      activeName:'first',
      showTab:true
    }
  },
  created(){
    this.contentStyleObj= this.$getHeight(280)
    if(this.$store.getters["commons/params"].editCustomerComId){
      this.showTab = false
    }
  },
  methods:{

  }
}
</script>

<style>
</style>